<template>
  <div>
    <footer>
      <div class="edition_wdth elasticity footer_maxbox">
        <div class="interest_left_box">
          <div class="interest_title_content">
            关注我们<span>Follow US</span>
          </div>
          <div class="interest_title_block"></div>
          <div class="elasticity interest_img_box">
            <div class="interest_img_div">
              <img :src="getImgSrc(siteinfodata.qr_code)" alt="" />
              <p>微信公众号</p>
            </div>
            <div class="interest_img_div">
              <img :src="getImgSrc(siteinfodata.wx_code)" alt="" />
              <p>官方微信</p>
            </div>
          </div>
        </div>
        <div class="navfooter_right_box">
          <div class="elasticity navfooter_list_box">
            <div
              class="navfooter_list_div"
              v-for="(item, index) in getnavlist"
              :key="index"
              v-if="item.status"
              @click.stop="Jumpchangbtn(item)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="elasticity navfooter_enterprise_info">
            <div class="contact_box">
              <div class="interest_title_content">
                联系我们 <span>Contact Us</span>
              </div>
              <div class="interest_title_block"></div>
              <div>热线电话：{{ siteinfodata.tel }}</div>
            </div>
            <div class="enterprise_center_box">
              <p>公司：{{ siteinfodata.company_name }}</p>
              <p>地址：{{ siteinfodata.address }}</p>
            </div>
            <div class="elasticity enterprise_icon_box">
              <img src="@/assets/bag_img.png" alt="" />
              <img src="@/assets/ruler_img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- 版权 -->
    <div class="footer_footer_box">
      <div class="elasticity edition_wdth">
        <div>
          版权所有 {{ siteinfodata.copyright }} <a style="color: #FFFFFF;" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">{{ siteinfodata.beian }}</a>
        </div>
        <div>技术支持： <a style="color: #FFFFFF;" href="https://www.htidc.net/">海腾数据</a></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    // 站点信息
    siteinfodata() {
      return this.$store.state.user.siteinfo;
    },
    // 用户信息
    getuserinfo() {
      return this.$store.state.user.userInfo;
    },
    // 路由列表
    getnavlist() {
      const route = this.$route;
      const { path, name } = route;
      let routerlist = this.$router.options.routes;
      let navlist = routerlist;
      return navlist;
    },
  },
  methods: {
    // 路由跳转
    Jumpchangbtn(e) {
      this.$router.push({
        name: e.children[0].name,
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 750px) {
  @import "../styles/navstyle.css";
}
</style>